<template>
  <el-dialog
    title="Edit the network model/data sets"
    :visible.sync="dialogEditFormVisible"
    width="80rem"
    :close-on-click-modal="false"
    ><el-divider>Basic information about the model</el-divider>
    <el-form :model="form" :rules="rules" ref="form">
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="Molecular properties"
            :label-width="formLabelWidth"
            prop="molecular_properties"
          >
            <el-input
              v-model="form.molecular_properties"
              placeholder="Enter a Property or Activity"
            ></el-input> </el-form-item></el-col
      ></el-row>
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="Data type"
            :label-width="formLabelWidth"
            prop="type"
          >
            <el-radio-group v-model="form.type">
              <el-radio label="TRAINED_NETWORKS_WITH_DATA"
                >trained networks with data</el-radio
              >
              <el-radio label="DATA_SETS">data sets</el-radio>
            </el-radio-group>
          </el-form-item></el-col
        ></el-row
      >
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="Total number of molecules"
            :label-width="formLabelWidth"
            prop="total_number_of_molecules"
          >
            <el-input
              v-model="form.total_number_of_molecules"
              placeholder="Enter the total number of molecules"
            ></el-input> </el-form-item></el-col
        ><el-col
          ><el-form-item
            label="Download link"
            :label-width="formLabelWidth"
            prop="download_address"
          >
            <el-input
              v-model="form.download_address"
              placeholder="Enter the download address"
            ></el-input> </el-form-item></el-col
      ></el-row>
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="Status"
            :label-width="formLabelWidth"
            prop="download_address"
          >
            <el-tag
              :type="
                form.status == 1
                  ? 'info'
                  : form.status == 2
                  ? 'success'
                  : form.status == 3
                  ? 'warning'
                  : 'danger'
              "
              >{{
                form.status == 1
                  ? "ToBeReviewed"
                  : form.status == 2
                  ? "Passed"
                  : form.status == 3
                  ? "Unpassed"
                  : "Offline"
              }}</el-tag
            ></el-form-item
          ></el-col
        ></el-row
      >
      <el-row>
        <el-col>
          <el-form-item
            label="Detailed description"
            :label-width="formLabelWidth"
            prop="details_content"
          >
            <WangEditor ref="WangEditor" @refreshContent="refreshContent" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleEditCancel()" icon="el-icon-circle-close"
        >Cancel</el-button
      >
      <el-button
        type="primary"
        :loading="isLoading"
        @click="handleSave()"
        icon="el-icon-circle-check"
        >Save</el-button
      >
    </div></el-dialog
  >
</template>
    
    <script>
import WangEditor from "../../../components/WangEditor/index.vue";
export default {
  name: "MyNetworkModelEdit",
  components: { WangEditor },
  data() {
    return {
      formLabelWidth: "15rem",
      dialogEditFormVisible: false,
      isLoading: false,
      form: {},
      //表单验证规则
      rules: {},
    };
  },
  methods: {
    handleOpen(data) {
      this.dialogEditFormVisible = true;
      this.form = data;
      this.$nextTick(() => {
        this.$refs.WangEditor.initHtml(this.form.details_content);
      });
    },
    handleSave() {
      this.$store
        .dispatch("myNetworkModel/edit", {
          un_code: this.form.un_code,
          molecular_properties: this.form.molecular_properties,
          type: this.form.type,
          total_number_of_molecules: this.form.total_number_of_molecules,
          download_address: this.form.download_address,
          details_content: this.form.details_content,
        })
        .then((res) => {
          this.$message.success("Save successfully!");
          this.handleEditCancel();
        })
        .catch(() => {
          this.$message.error("Save failed!");
        });
    },
    handleEditCancel() {
      this.dialogEditFormVisible = false;
      this.$emit("getInfo");
    },
    //编辑更新内容回调函数
    refreshContent(content) {
      this.form.details_content = content;
    },
  },
};
</script>
    
    <style>
</style>