<template>
  <el-card class="info_card">
    <div slot="header">
      <el-row type="flex" justify="space-between"
        ><el-col :span="10"><span>My network model/data sets</span></el-col>
        <el-col :span="10">
          <el-input
            placeholder="Enter a Property or Activity"
            v-model="keyWord"
            class="input-with-select"
            @keyup.enter.native="getInfo"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getInfo"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="1.5">
          <el-button
            type="success"
            round
            icon="el-icon-circle-plus-outline"
            @click="Add()"
            :loading="isLoading"
            >Add</el-button
          ></el-col
        ></el-row
      >
    </div>
    <div>
      <el-table :data="infoList" border style="width: 100%">
        <el-table-column prop="molecular_properties" label="Molecular properties">
        </el-table-column>
        <el-table-column prop="type" label="Type">
        </el-table-column>
        <el-table-column prop="status" width="115" label="Status"
          ><template slot-scope="scope">
            <el-tag
              :type="
                scope.row.status == 1
                  ? 'info'
                  : scope.row.status == 2
                  ? 'success'
                  : scope.row.status == 3
                  ? 'warning'
                  : 'danger'
              "
              >{{
                scope.row.status == 1
                  ? "ToBeReviewed"
                  : scope.row.status == 2
                  ? "Passed"
                  : scope.row.status == 3
                  ? "Unpassed"
                  : "Offline"
              }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="Operatiion" width="100">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >Edit</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      <MyNetworkModelAdd @getInfo="getInfo" ref="MyNetworkModelAdd" />
      <MyNetworkModelEdit :form="form" ref="MyNetworkModelEdit" @getInfo="getInfo" />
    </div>
  </el-card>
</template>
<script>
import MyNetworkModelAdd from "../MyNetworkModelAdd/index.vue";
import MyNetworkModelEdit from "../MyNetworkModelEdit/index.vue"
export default {
  name: "MyNetworkModelSelect",
  components: { MyNetworkModelAdd,MyNetworkModelEdit },
  data() {
    return {
      //分页数据--start
      pageSize: 10,
      pageNum: 1,
      total: 0,
      //分页数据--end
      infoList: [],
      form: {},
      isLoading: false,
      //模糊搜索关键词
      keyWord:""
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    Add() {
      this.$refs.MyNetworkModelAdd.handleOpen();
    },
    getInfo() {
      this.$store
        .dispatch("myNetworkModel/select", {
          pagesize: this.pageSize,
          pagenum: this.pageNum,
          keyWord: this.keyWord,
        })
        .then((res) => {
          var { data } = res;
          this.infoList = data.info_list;
          this.total = data.total;
        });
    },
    //分页控件函数--start
    handleSizeChange(val) {
      this.pageSize = val;
      this.getInfo();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getInfo();
    },
    //分页控件函数--end
    //编辑列函数
    handleEdit(index, row) {
      this.form = row;
      this.$refs.MyNetworkModelEdit.handleOpen(this.form);
    },
    //删除列函数
    // handleDelete(index, row) {
    //   this.$confirm("此操作将删除该网络模型相关信息 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       this.$store
    //         .dispatch("myNetworkModel/del", { un_code: row.un_code })
    //         .then((res) => {
    //           this.$message.success(res.msg);
    //           this.getInfo();
    //         });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "error",
    //         message: "删除失败",
    //       });
    //     });
    // },
  },
};
</script>
    
    <style>
</style>