<template>
  <el-dialog
    title="Add a network model/data sets"
    :visible="dialogAddFormVisible"
    width="70rem"
    :close-on-click-modal="false"
  >
    <el-form :model="form" :rules="rules" ref="form">
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="Molecular properties"
            :label-width="formLabelWidth"
            prop="molecular_properties"
          >
            <el-input
              v-model="form.molecular_properties"
              placeholder="Enter a Property or Activity"
            ></el-input> </el-form-item></el-col
      ></el-row>
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="Data type"
            :label-width="formLabelWidth"
            prop="type"
          >
            <el-radio-group v-model="form.type">
              <el-radio label="TRAINED_NETWORKS_WITH_DATA">trained networks with data</el-radio>
              <el-radio label="DATA_SETS">data sets</el-radio>
            </el-radio-group>
          </el-form-item></el-col
        ></el-row>
      <el-row type="flex"
        ><el-col
          ><el-form-item
            label="Total number of molecules"
            :label-width="formLabelWidth"
            prop="total_number_of_molecules"
          >
            <el-input
              v-model="form.total_number_of_molecules"
              placeholder="Enter the total number of molecules"
            ></el-input> </el-form-item></el-col
        ><el-col
          ><el-form-item
            label="Download link"
            :label-width="formLabelWidth"
            prop="download_address"
          >
            <el-input
              v-model="form.download_address"
              placeholder="Enter the download address"
            ></el-input> </el-form-item></el-col
      ></el-row>
      <el-row>
        <el-col>
          <el-form-item
            label="Detailed description"
            :label-width="formLabelWidth"
            prop="details_content"
          >
            <WangEditor ref="WangEditor" @refreshContent="refreshContent" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleAddCancel()" icon="el-icon-circle-close"
        >Cancel</el-button
      >
      <el-button
        type="primary"
        :loading="isLoading"
        @click="handleAdd()"
        icon="el-icon-circle-check"
        >Create</el-button
      >
    </div>
  </el-dialog>
</template>
    
    <script>
import WangEditor from "../../../components/WangEditor/index.vue";
export default {
  name: "MyNetworkModelAdd",
  components: { WangEditor },
  data() {
    return {
      dialogAddFormVisible: false,
      isLoading: false,
      formLabelWidth: "15rem",
      form: {
        molecular_properties: "",
        type: "TRAINED_NETWORKS_WITH_DATA",
        total_number_of_molecules: "",
        download_address: "",
        details_content: "",
      },
      //表单验证规则
      rules: {},
    };
  },
  methods: {
    handleOpen() {
      this.dialogAddFormVisible = true;
      this.$refs.WangEditor.initHtml();
    },
    handleAdd() {
      this.isLoading = true;
      this.$store
        .dispatch("myNetworkModel/add", this.form)
        .then((res) => {
          this.$message.success(res.msg);
          this.handleAddCancel();
          this.$emit("getInfo");
        })
        .catch((err) => {
          console.log(err);
        });
      this.isLoading = false;
    },
    handleAddCancel() {
      this.form = {};
      this.dialogAddFormVisible = false;
    },
    //编辑更新内容回调函数
    refreshContent(content) {
      this.form.details_content = content;
    },
  },
};
</script>
    
    <style>
</style>